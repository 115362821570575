/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
 var stringToHTML2 = function (str) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str, 'text/html');
	return doc.body;
};


var stringToHTML = function (str) {
	var div = document.createElement('div');
	div.innerHTML = str.trim();
	return div.firstChild;
};

export default stringToHTML;

var strToHTML = function (str) {
	var div = document.createElement('div');
	div.innerHTML = str.trim();
	return div;
};

import data from './data';
function getRegion(cId,rId) {
  let c=data.filter(e => e.id==cId)[0]
  return c.locs.filter(e => e.id==rId)[0]
}
function getRegionParams() {
  let params = new URLSearchParams(window.location.search)
  let c=data.filter(e => e.id==params.get('c'))[0]
  return c.locs.filter(e => e.id==params.get('r'))[0]
}
function getCity(id) {
  return data.filter(e => e.id==id)[0]
}
function getCityParams() {
	let params = new URLSearchParams(window.location.search)
  return data.filter(e => e.id==params.get('c'))[0]
}
var sthId = function (str, id) {
	var div = document.createElement('div');
	div.id=id;
	div.innerHTML = str.trim();
	return div;
};

function checkMobile(){
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
		// true for mobile device
		return true;
	} else {
		// false for not mobile device
		return false;
	}
}

function loadVid(id, name) {
	document.getElementById(id).src=name
}

export {strToHTML, sthId, getCity, getCityParams, getRegion, getRegionParams, checkMobile, loadVid};