import {getCity,getRegion} from './cc-utils'

export default function showLocation(ur) {
  console.log('I get called from print.js!', ur);

  let f360= document.getElementById('frame360')
  f360.src=ur

}

function setTextView(cId, rId){
  let tx_city=document.getElementById('fx-city')
  tx_city.textContent=getCity(cId).city.toUpperCase()

  let tx_loc=document.getElementById('fx-location-1')
  tx_loc.textContent=getRegion(cId, rId).loctxt1
}

export {setTextView};