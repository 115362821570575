
import './btn-20deg.css'
import './btn-20deg-m.css'

import sth, {checkMobile, getCity,getRegion, sthId} from './cc-utils'

import './index.css'
import './index-m.css'
import hea from "./side/header.html";
import './side/header.css'
import './side/header-m.css'
import IconEB from './eb.png';
import IconPB from './pb.png';
document.body.appendChild(sth(hea));
document.getElementById('med-mmx-ico').src=IconEB
document.getElementById('med-mmx-ico-2').src=IconPB
import(/* webpackPreload: true */ './side/router')

import bot from "./side/bottom.html";
import './side/bottom.css'
import IconGOB from './gob.png';
document.body.appendChild(sth(bot));
document.getElementById('med-mmx-ico-3').src=IconGOB

//document.getElementById('med-mmx-ico').src=IconEB
//document.getElementById('med-mmx-ico-2').src=IconPB
//document.getElementById('med-mmx-ico-3').src=IconGOB

console.log('Fer');
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}


const mapIco = document.getElementById("mpb-ico");
mapIco.addEventListener("click", () => {
  console.log('xxx');
  //mapIco.className+=' open-eb'
  mapIco.classList.toggle('open-eb')
});

const mapIcoS = document.getElementById("mpb-ico-s");
mapIcoS.addEventListener("click", () => {
  //mapIco.style.display='flex'
  mapIco.classList.toggle('open-eb')
});

import {st} from './data'
import FD_IMG from './res/fd.jpeg'
let fd_a=document.createElement('a')
let fd=document.createElement('img')
fd.id='fd-ic-link'
fd.classList='fd-ic'
fd.src=FD_IMG
fd_a.appendChild(fd)
fd_a.href=st.fd
document.body.appendChild(fd_a)

document.getElementById("mpb-ico-gm").addEventListener("click", () => {
  window.location.href = `omap.html?c=${cId}`;
});


document.getElementById("mpb-ico-info").addEventListener("click", () => {
  //window.location.href = `comercio.html?s=${city}&r=`;
  
  window.location.href = `comercio.html?c=${cId}&r=${rId}`;
  //comercio.html?c=cbba&r=cbba002
});

let city = 'La Paz'
/*
fetch("https://ipinfo.io/json?token=2261d0e05ab135",{
  headers: {
    'Content-Type': 'application/json',
  }
}
).then(
  (response) => response.json()
).then(
  (jsonResponse) => {

    console.log(jsonResponse.ip, jsonResponse.country, jsonResponse.city)
    switch (jsonResponse.region) {
      case 'La Paz':
        let city= document.getElementById('ccf-city')
        city.innerHTML=jsonResponse.city

        let f360= document.getElementById('frame360')
        //f360.src='/lp1/index.html'

        break;
    
      default:
        break;
    }
  }

)
*/

let f360= document.getElementById('frame360')
f360.src='/lp2/index.html'

import showLocation, {setTextView} from './actions';
function componentBtnLocation(cities) {
  const element = document.getElementById('btn-ctn-loc')
  element.innerHTML=''
  for (const el of cities.locs) {
    const btn = document.createElement('button');
    btn.id = el.id;
    btn.innerHTML = el.eb;
    btn.onclick = (e) => { 
      rId=el.id
      showLocation(el.f360)
      setTextView(cId,rId)
    }
    btn.classList='btn-20d'
    element.appendChild(btn);
  }
}

function componentDefLocation(cities){
  let f360=document.getElementById('frame360')
  f360.src=cities.locs[0].f360
}

import data from './data';
function getCities(data){
  console.log(city);
  return data.filter(el=> el.city==city)[0]
}

//console.log(getCities(data));
componentBtnLocation(getCities(data));


let cId='lpz'
let rId='lpz001'
setTextView(cId, rId)



const lpzEv = document.getElementById('path9396');
lpzEv.addEventListener('click', () => {
  //city='La Paz'
  cId='lpz', rId='lpz001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
  setTextView(c);
});

const cbbEv = document.getElementById('path9394');
cbbEv.addEventListener('click', () => {
  city='Cochabamba'
  cId='cbba', rId='cbba001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
  
});
const tjEv = document.getElementById('path9384');
tjEv.addEventListener('click', () => {
  city='Tarija'
  cId='trj', rId='trj001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
});

const scEv = document.getElementById('path9388');
scEv.addEventListener('click', () => {
  city='Sucre'
  cId='scr', rId='scr001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
});
const ptsEv = document.getElementById('path9386');
ptsEv.addEventListener('click', () => {
  city='Potosi'
  cId='pts', rId='pts001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
});
const oroEv = document.getElementById('path9390');
oroEv.addEventListener('click', () => {
  city='Oruro'
  cId='oro', rId='oro001'
  setTextView(cId,rId);
  componentBtnLocation(getCities(data));
  componentDefLocation(getCities(data));
});



let path9396 = document.getElementById('path9396')
path9396.addEventListener('mouseenter', ()=>{
  console.log('svg - mouseenter');
  
  /*x(svg,path9396).then(()=>{
    console.log('svg');
    path9396.classList.toggle('svg-hover')
  })*/

  //path9396.classList=('svg-hover')
  //document.getElementById('path9396').classList=('svg-hover')
  //document.getElementById('LA-PAZ').classList='svg-hover'
})

/*
let mpb = document.getElementById('svg-mpb-1')
path9396.addEventListener('mouseleave', ()=>{
  console.log('svg - mouseleave');
  path9396.classList=''
  //document.getElementById('LA-PAZ').classList=''
})
*/


let path9394 = document.getElementById('path9394')
path9394.addEventListener('mouseenter', ()=>{
  //svg.appendChild(path9394);
  //path9394.classList=('svg-hover')
  //document.getElementById('LA-PAZ').classList='svg-hover'
})
/*
path9394.addEventListener('mouseleave', ()=>{
  path9394.classList=''
  //document.getElementById('LA-PAZ').classList=''
})


let path9390 = document.getElementById('path9390')
path9390.addEventListener('mouseenter', ()=>{
  path9390.classList=('svg-hover')
  //document.getElementById('LA-PAZ').classList='svg-hover'
})
path9390.addEventListener('mouseleave', ()=>{
  path9390.classList=''
  //document.getElementById('LA-PAZ').classList=''
})
*/


import { SVG } from '@svgdotjs/svg.js'

let element = SVG('#path9384')
let path9384=document.getElementById('path9384')
let animtrj=document.getElementById('animtrj')
let animtrj2=document.getElementById('animtrj2')

element.on('mouseenter', ()=>{
  console.log('svg.js - mouseenter')
  //svg.appendChild(path9384)
  
  
  //values="1;1.5;1;1.5;1"
  //type="scale"
  //from="0 0" to="2.4 2.4"
  /* animtrj.setAttribute('type', 'scale')
  animtrj.setAttribute('from', '0 0')
  animtrj.setAttribute('to', '2.4 2.4')
  animtrj.setAttribute('dur', '1s')

  animtrj2.setAttribute('type', 'translate')
  animtrj2.setAttribute('dur', '1s')
  animtrj2.setAttribute('values', '-500,-1100')
  animtrj2.setAttribute('additive', 'sum')
  
  document.getElementById('path9384').blur() */
  
  
})
element.on('mouseleave', ()=>{
  //console.log('svg.js - mouseleave')

  /* animtrj.setAttribute('to', '1 1')
  animtrj2.setAttribute('values', '0,0') */
  
})
/*
let path9384 = document.getElementById('path9384')
path9384.addEventListener('mouseenter', (event)=>{
  
  draw.front()
  draw.animate().fill('#f03').scale(1.5)
  
  //console.log('fer svg', draw);

  //event.preventDefault();
  //svg.appendChild(path9384)
  //path9384.setAttribute("transform", "scale(1.1)");
  //path9384.style='transition: all 1s ease-out;'
  //path9384.classList=('svg-hover')
  //document.getElementById('LA-PAZ').classList='svg-hover'
})
path9384.addEventListener('mouseleave', ()=>{
  console.log('mouseleave');
  draw.scale(1)
})*/

async function x(s, ch){  
  return await s.appendChild(ch);
}


let lel=document.getElementById('mmx-list-el')
lel.addEventListener('click', () => {
  if (!checkMobile()) {
    
    window.location.href='/omap.html'
  }
})
