

const Data = [
  {
    'city': 'La Paz',
    'id': 'lpz',
    'reg': 'LA PAZ - BOLIVIA',
    location: { lat: -16.4508872, lng: -68.1690538, zo: 12.83 },
    coordinate_: [ -7590060.038617465, -1861769.9172049586 ],
    'locs': [
      { 'id':'lpz002', 'eb': 'La Paz - Sede de Gobierno', 'f360': '/lp2/index.html', location: { lat: -16.497546, lng: -68.135502 },
        'loctxt1':'Socabaya (Sede de Gobierno)',
        'loctxt2':'Av. Mariscal Sta. Cruz', 
        'loctxt3':'C. Mercado',
        'title':'GALERÍA MADE IN BOLIVIA',
        'aso':'PUNTO HECHO EN BOLIVIA “GALERÍA MADE IN BOLIVIA” ',
        'p': [
          {'t':'Nace el 11 de septiembre de 2018 cuando Micro empresarios de distintos rubros, que participaban en ferias locales y nacionales, tejieron una red de contactos e identificaron una necesidad común, “CONTAR CON UN ESPACIO PARA COMERCIALIZAR SU PRODUCCIÓN”, Es así que consiguen organizarse y con gran esfuerzo, logran establecer la GALERIA MADE IN BOLIVIA, dentro de su oferta de productos se pueden encontrar calzados, prendas de vestir, alimentos nutricionales, miel de abeja, café, marroquinería y Joyería en Plata.'},
        ],
        'back1': '/media/lpz002/bk1.png',
        'back2': '/media/lpz002/bk2.png',
        'vidprod1': '/media/lpz002/vidprod1.mp4',
        'caprod1': '/media/lpz002/caprod1.pdf',
        'fb1': 'https://www.facebook.com/madeinboliviaproductores'
      },
      { 'id':'lpz001', 'eb': 'El Alto - Feria 16 de Julio', 'f360': '/lp1/index.html', location: { lat: -16.492807, lng: -68.183339 }, 
        'loctxt1':'Pasaje 25 de Mayo (Teleferico Azul)',
        'loctxt2':'Av. La Paz', 
        'loctxt3':'C. Balboa Garcia',
        'title':'ASOCIACION DE ARTESANOS CARPINTEROS 19 DE MARZO',
        'aso':'ASOCIACION: CARPINTEROS 19 DE MARZO',
        'p': [
          {'t':'La Asociación de Artesanos Carpinteros 19 de marzo, cuenta con más 500 asociados especializados en la elaboración de muebles en maderas, en la gestión 2020 cumplieron 50 años desde su creación, siendo reconocidas como una de las asociaciones productivas con mayor historia de la ciudad de El Alto.  comercializan sus productos en la Feria 16 de Julio, los días jueves y domingo.'},
          {'t':'Los años de experiencia en el rubro, es garantía de contar con mano de obra calificada, y productos de alto valor y resistencia, cumpliendo de esta manera con las exigencias del mercado.'},
        ],
        'back1': '/media/lpz001/bk1.png',
        'back2': '/media/lpz001/bk2.png',
        'vidprod1': '/media/lpz001/vidprod1.mp4',
        'caprod1': '/media/lpz001/caprod1.pdf',
        'fb1': 'https://www.facebook.com/madeinboliviaproductores'
      }
    ]
  },
  {
    'city': 'Cochabamba',
    'id': 'cbba',
    location: { lat: -17.4063253, lng: -66.1533998, zo: 14.17 },
    coordinate_: [-7364685.072521024, -1970082.443912741],
    'reg': 'CBBA - BOLIVIA',
    'locs': [
      { 'id':'cbba002', 'eb': 'Muebles (Zona de Lareto)', 'f360': '/cbb2/index.html', location: {lat: -17.422797, lng: -66.158023}, 
        'loctxt1':'Av. Lareto y Ayacucho',
        'loctxt2':'Av. La Cocha', 
        'loctxt3':'Av.  Garcia',
        'title': 'FERIA DEL MUEBLE DEL PRODUCTOR AL CONSUMIDOR “ZARSOLEPS”',
        'aso':'FERIA DEL MUEBLE - ZASOLEPS',
        'p': [
          {'t':'El punto hecho en Bolivia – FERIA DEL MUEBLE – ZASOLEPS está conformada por tres asociaciones de carpinteros: Paulo Zárate Villka, Sol Naciente y Nueva Esperanza..'},
          {'t':'Son Productores Bolivianos, con varios años de experiencia en el Mercado, todos los socios cuentan con talleres propios perfectamente acondicionados y equipados, logrando de esta manera elaborar productos durables y de alta calidad..'},
          {'t':'Los muebles son elaborados con maderas nobles como pino, roble, cedro, cedrillo, provenientes principalmente del cono sur de Cochabamba..'},
        ],
        'back1': '/media/cbba002/bk1.jpg',
        'vidprod1': '/media/cbba002/vidprod1.mp4',
        'caprod1': '/media/cbba002/caprod1.pdf',
      },
      { 'id':'cbba001', 'eb': 'Textiles (Mañaneras de la Cancha)', 'f360': '/cbb1/index.html', location: {lat: -17.402251, lng: -66.156723}, 
        'loctxt1':'Av. Tarata (Terminal de Buses)',
        'loctxt2':'Av. La Paz', 
        'loctxt3':'C. Balboa Garcia',
        'title': 'FERIA LA CANCHA',
        'aso':'FERIA DEL TEXTILES',
        'p': [
          {'t':'La feria del mueble está conformada por tres asociaciones de carpinteros: Paulo Zárate Villka, Sol Naciente y Nueva Esperanza.'},
          {'t':'Somos productores bolivianos, con talleres equipados y con personal calificado, por lo que proporcionamos productos de alta calidad a un precio razonable. Proporcionamos una amplia selección de muebles de calidad que no encontrará en ningún otro lugar.'},
          {'t':'Entre los maestros contamos con los mejores fabricantes y diseñadores herederos de generaciones de esta noble profesión.'},
          {'t':'Los muebles que diseñan las diferentes unidades productivas representan lo último en productos modernos y elegantes con modelos minimalistas.'},
          {'t':'Tenemos la visión de que la mano de obra calificada de Bolivia exporte un producto acabado con valor agregado. Esperamos que nuestro trabajo trascienda las fronteras llevando consigo la marca de calidad Hecho en Bolivia.'},
          {'t':'Trabajamos en variedad de maderas como el pino, que proviene principalmente del Cono Sur de Cochabamba, Colomi, Sacaba, Tiraque, Vacas, Mizque, Pocona, Cuchumuela y Arani. También trabajamos con roble, cedro, cedrillo y trompillo que son trasladadas desde los bosques tropicales de Cochabamba.'},
        ],
        'back1': '/media/cbba001/bk1.jpg',
        'vidprod1': '/media/cbba001/vidprod1.mp4',
        'caprod1': '/media/cbba001/caprod1.pdf',
      },
      
    ]
  },
  {
    'city': 'Tarija',
    'id': 'trj',
    'reg': 'TRJ - BOLIVIA',
    location: { lat: -21.5403972, lng: -64.725316, zo: 18.14 },
    coordinate_: [-7205188.947584709, -2456562.0494736335],
    'locs': [
      { 'id':'trj001', 'eb': 'Feria Ex-Terminal', 'f360': '/tj1/index.html', 
        location: { lat: -21.5414972, lng: -64.725316, zo: 19.14 }, 
        'loctxt1':'Ovelisco Tarija (Ex-Terminal)',
        'loctxt2':'Av. Angel Calavi', 
        'loctxt3':'Humberto Ichazu',
        'title': 'FERIA ARTESANAL “TARIJA EXPONE”',
        'aso':'PUNTO HECHO EN BOLIVIA “TARIJA EXPONE “',
        'p': [
          {'t':'Feria Tarija Expone (Ex Terminal de Buses).- Es una Feria Productiva Artesanal compuesta por distintos productores locales con años de experiencia en sus respectivos rubros, con mucho esfuerzo, tras varias de gestiones y de manera organizada,   lograron conseguir un ambiente con infraestructura amplia y permanente, que cuenta con las condiciones adecuadas para la exposición de sus productos, en este espacio participan alrededor de 60 productores del sector Madera, Textil, Alimentos, Artesanías, con una oferta de productos, muy interesante y exclusiva de productos Hechos en Bolivia.'},
        ],
        'back1': '/media/trj001/bk1.jpg',
        'vidprod1': '/media/trj001/vidprod1.mp4',
        'caprod1': '/media/trj001/caprod1.pdf',
      },
    ]
  },
  {
    'city': 'Sucre',
    'id': 'scr',
    'reg': 'SCR - BOLIVIA',
    location: { lat: -19.0374073, lng: -65.2462538, zo: 18 },
    coordinate_: [-7263178.853438267, -2159493.619617826],
    'locs': [
      { 'id':'scr001', 'eb': 'Feria Hecho en Sucre', 'f360': '/sc1/index.html', 
        location: { lat: -19.0386973, lng: -65.2462538, zo: 18 }, 
        'loctxt1':'Esquina (Gasolinera ES GasCenter GNV)',
        'loctxt2':'Av. Ostria Gutierrez', 
        'loctxt3':'Martin Cruz',
        'title': 'Feria Hecho en Sucre',
        'aso':'Feria de Productores Hecho en Sucre',
        'p': [
          {'t':'La Feria de Productores Hecho en Sucre, está conformado por Micro Empresarios que forman parte de la Asociación ADEPROCH, asociación Multidisciplinaria, con varios años de experiencia, produciendo y comercializando productos en Textiles, Cueros, Alimentos, Madera y Artesanías.'},
          {'t':'Forman parte de la asociación alrededor de 30 Micro Empresarios, quienes, por iniciativa propia, iniciaron sus actividades en el año 2018, logrando constituir un espacio de comercialización propio, que les permite dar a conocer a sus clientes toda la oferta de productos hechos en Bolivia.'},
          {'t':'La variedad de productos que se ofertan en el punto hecho en Bolivia, se caracteriza por su diseño, innovación y calidad.'},
          
        ],
        'back1': '/media/scr001/bk1.png',
        'back2': '/media/scr001/bk2.png',
        'vidprod1': '/media/scr001/vidprod1.mp4',
        'caprod1': '/media/scr001/caprod1.pdf',
        'fb1':'https://www.facebook.com/FERIA-HECHO-en-SUCRE-112683567873266'
      },
    ]
  },
  {
    'city': 'Oruro',
    'id': 'oro',
    location: { lat: -17.968099, lng: -67.110051, zo: 16.17 },
    coordinate_: [-7470649.273439181, -2034299.040715603],
    'reg': 'ORURO - BOLIVIA',
    'locs': [
      { 'id':'oro001', 'eb': 'Feria de Muebles (Estación de Trenes)', 'f360': '/oro1/index.html', location: {lat: -17.972399, lng: -67.110051}, 
        'loctxt1':'Av. Velasco Galbarro sobre estación de trenes',
        'loctxt2':'Calle Sucre', 
        'loctxt3':'C. Aldana',
        'title': 'FERIA DEL MUEBLE 19 DE MARZO ',
        'aso':'EL PUNTO HECHO EN BOLIVIA - LA FERIA DEL MUEBLE 19 DE MARZO',
        'p': [
          {'t':'Es una de las ferias más antiguas de Oruro con más de 50 años de funcionamiento, llegando a ser parte de la historia de la ciudad.'},
          {'t':'Los productores en madera que forman parte de la feria, se caracterizan por ser productores con amplia experiencia, el conocimiento que poseen fue transmitido de generación en generación, habiendo desarrollado de esta manera técnicas de producción que garantizan la calidad de los productivos.'},
          {'t':'Los muebles que se pueden encontrar en la feria, son de alta calidad a precios razonable, los mismos se elaboran con una variedad de maderas nobles como el roble, cedro y madera blanca, estas maderas provienen del norte de La Paz. Dentro del punto hecho en Bolivia, además, podrán encontrar productos en metal mecánica como hornos semi industriales e industriales.'},
        ],
        'back1': '/media/oro001/bk1.png',
        'back2': '/media/oro001/bk2.png',
        'vidprod1': '/media/oro001/vidprod1.mp4',
        'caprod1': '/media/oro001/caprod1.pdf',
        'fb1':'https://www.facebook.com/Asociaci%C3%B3n-de-carpinteros-19-de-Marzo-107527918432570/',
      },
    ]
  },
  {
    'city': 'Potosi',
    'id': 'pts',
    location: { lat: -19.5611226, lng: -65.7636904, zo: 15.97 },
    coordinate_: [ -7320849.239316262, -2221599.2721859035],
    'reg': 'POTOSI - BOLIVIA',
    'locs': [
      { 'id':'pts001', 'eb': '“METAL MECANICA”', 'f360': '/pts1/index.html', location: {lat: -19.5652774, lng: -65.7643188}, 
        'loctxt1':'Av. Los Pinos',
        'loctxt2':'Villa Imperial', 
        'loctxt3':'6 de Julio',
        'title': 'PUNTO HECHO EN BOLIVIA “METAL MECANICA” Y TEXTIL',
        'aso':'PUNTO HECHO EN BOLIVIA “METAL MECANICA” Y TEXTIL',
        'p': [
          {'t':'La Asociación de Soldadores Potosí está integrada por alrededor de 20 afiliados, fue fundada el 04 de junio de 2014 y se puede encontrar la mayoría de sus ambientes de soldadura en la Zona de los Pinos de la ciudad de Potosí, son muy reconocidos  por la calidad, novedad de sus trabajos, el tiempo de entrega y la calidez con la que atienden a sus clientes, ellos se encuentran muchas veces fuera de sus negocios porque de manera constante están con entregas o colocando las estructuras realizadas  en diferentes lugares. Ellos procuran introducir un enfoque de género en la contratación del personal, por eso hoy hay mujeres trabajando con ellos.'},
          {'t':'El sector de textiles en Potosí, está compuesta por varias asociaciones que datan de aproximadamente 7 años atrás, el Punto Hecho en Bolivia identificado se encuentra en el centro histórico de la ciudad, dónde abarca a la gran mayoría; entre sus principales ofertas están desde confección de trajes, ropa deportiva, ropa juvenil, ropa de abrigo, ropa con tendencia de moda actual, para todas las edades desde niños hasta personas adultas, uno solo tiene que identificar los letreros de Punto Hecho en Bolivia. Los precios son justos y están hechas con el cariño potosino.'},
        ],
        'back1': '/media/pts001/bk1.png',
        'back2': '/media/pts001/bk2.png',
        'vidprod1': '/media/pts001/vidprod1.mp4',
        'caprod1': '/media/pts001/caprod1.pdf',
        'fb1':'https://www.facebook.com/106866048501168/posts/106878185166621/?d=n',
      },
      { 'id':'pts002', 'eb': 'TEXTILES', 'f360': '/pts2/index.html', location: {lat: -19.5652774, lng: -65.7643188}, 
        'loctxt1':'Zona Central',
        'loctxt2':'Calle Quijarro Esq. Bolívar #44',
        'loctxt3':'',
        'title': 'PUNTO HECHO EN BOLIVIA TEXTILES',
        'aso':'Sector de textiles en Potosí',
        'p': [
          {'t':'El sector de textiles en Potosí, está compuesta por varias asociaciones que datan de aproximadamente 7 años atrás, el Punto Hecho en Bolivia identificado se encuentra en el centro histórico de la ciudad, dónde abarca a la gran mayoría; entre sus principales ofertas están desde confección de trajes, ropa deportiva, ropa juvenil, ropa de abrigo, ropa con tendencia de moda actual, para todas las edades desde niños hasta personas adultas, uno solo tiene que identificar los letreros de Punto Hecho en Bolivia. Los precios son justos y están hechas con el cariño potosino.'},
        ],
        'back1': '/media/pts002/bk1.png',
        'back2': '/media/pts002/bk2.png',
        'vidprod1': '/media/pts002/vidprod1.mp4',
        'caprod1': '/media/pts002/caprod1.pdf',
        'fb1':'https://www.facebook.com/Textiles-Potosinos-109021741625931',
      },
    ]
  },

];

export default Data;

const st={
  fd:'/#',
}
const eb_doc = {
  center: { lat: -15.2817064, lng: -63.5493965 },
  zoom: {mobile: 4.87, normal: 5.87}
}
export {st, eb_doc};